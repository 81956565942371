// tipsmaster.com
// export const Site='660d14694b28e60d21172a3d' 

// mybankbazaar.com 
// export const Site='6613e695eb7282e7a7e46be0'  

// thehealthgossip.com 
// export const Site='66274d058f16fbd00a096477'

// majihinaukari.com 
export const Site='66f26b2a9dc41ab0cf7ff38a'